import styled from "styled-components";

export const Hero = styled.section`
  position: relative;
  padding: 80px 0;
  margin-bottom: 80px;
  border-bottom: solid 1px var(--accent-20);
  .hero-title {
    display: flex;
    flex-direction: column;
    align-items: left;

    @media (max-width: 1199px) {
      align-items: center;
    }

    > * {
      text-align: center;
      @media (min-width: 1200px) {
        text-align: left;
      }
    }

    h5 {
      text-transform: none;
      font-size: 20px;
      line-height: 30px;
      font-weight: 200;
      opacity: 0.7;
    }

    h5,
    p {
      max-width: 600px;
    }

    a {
      width: 100%;
      max-width: 500px;
      @media (min-width: 1200px) {
        max-width: 300px;
      }
    }

    h2 {
      margin-top: 40px;
      margin-bottom: 0;
      @media (min-width: 1200px) {
        margin-top: 0;
      }
    }

    ul.artists {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-wrap: wrap;

      justify-content: center;

      @media (min-width: 950px) {
        justify-content: left;
      }

      li {
        white-space: no-wrap;
        font-family: var(--display-font);
        font-size: 14px;
        line-height: 21px;
        &:not(:last-of-type):after {
          content: "•";
          margin: 0 8px;
        }
      }
    }
  }
`;

export const FlexHero = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  @media (min-width: 1200px) {
    flex-direction: row;
    justify-content: space-around;
  }
`;

export const HeroImageWrapper = styled.div`
  border-radius: 8px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.5);
  width: 100%;
  position: relative;
  max-width: 450px;
  overflow: hidden;
  &::after {
    content: "";
    width: 100%;
    padding-bottom: 100%;
    display: block;
    position: relative;
  }
  img {
    width: 100%;
    height: 100%;
    obejct-fit: cover;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
`;
