import { ReactElement } from "react";
import { ArtistSectionWrapper } from "./styles";

type Props = {
  name: string;
  description: string | ReactElement;
  id: number;
};

export const ArtistSection = ({
  name,
  description,
  id,
}: Props): JSX.Element => {
  return (
    <ArtistSectionWrapper
      className="about-area artist-section"
      id={`artist_${id}`}
    >
      <div className="container about-container">
        <div className="artist-title">
          <h2>{name}</h2>
          <p>{description}</p>
        </div>
      </div>
    </ArtistSectionWrapper>
  );
};
