/* eslint-disable prettier/prettier */
export const artists = [
  {
    name: "hildabroom",
    description: (
      <>
        <a
          href="https://twitter.com/Hildabroom7"
          target="_blank"
          rel="noreferrer"
        >
          hildabroom
        </a>
        &nbsp; wears many hats in the world of web3 and his favorite is
        curation. Starting as a collector in early 2021 he quickly began
        amassing digital collectibles and managing communities for both
        ThankYouX and tjo. Through teaming up with tjo during his #bleu movement
        they&apos;ve built a strong community of artists+ collectors. It is
        through this means that hilda has been provided the opportunity to lift
        artists up, and often work directly towards our mutual goals.
      </>
    ),
  },
  {
    name: "Anzomez",
    description: (
      <>
        <a href="https://twitter.com/anzomez" target="_blank" rel="noreferrer">
          Anzomez
        </a>
        &nbsp; Purple Process.
      </>
    ),
  },
  {
    name: "bbdrip",
    description: (
      <>
        <a href="https://linktr.ee/whoesoe" target="_blank" rel="noreferrer">
          bbdrip
        </a>
        &nbsp; has been capturing moments of his life, and those around him, as
        a hobby photographer for over a decade. Driven by the desire to grasp
        his world and feelings through imagery, trapping these ever fleeting
        moments are a way for him to cherish the time passed.
      </>
    ),
  },
  {
    name: "bojan",
    description: (
      <>
        <a
          href="https://linktr.ee/bojan_archnd"
          target="_blank"
          rel="noreferrer"
        >
          bojan
        </a>
        &nbsp; (b.1998) is a visual narrator identifying his artistic style as
        “caliginous surrealism”. He aims to portray experiences and events
        throughout life, while also striving to deliver a message, a wake-up
        call through a variety of emotions and mental states of his characters,
        through the oddity of his objects, through the gloominess of his
        atmospheres. Poetry plays a crucial part in Bojan’s pieces as he always
        includes hints and messages in his poems revealing the hidden meaning
        behind his work.
      </>
    ),
  },
  {
    name: "Irina Yufereva",
    description: (
      <>
        <a href="https://linktr.ee/Yufereva" target="_blank" rel="noreferrer">
          Irina Yufereva
        </a>
        &nbsp; is an NFT and physical artist using traditional media
        (oil/watercolor/pastel) to create metaphorical landscapes and narrative
        paintings.
      </>
    ),
  },
  {
    name: "Manfredi Caracciolo",
    description: (
      <>
        <a
          href="https://twitter.com/itsfredi1"
          target="_blank"
          rel="noreferrer"
        >
          Manfredi Caracciolo
        </a>
        &nbsp; is an Italian digital artist and art director. His style has
        graphical and composition-oriented influences with a strong focus on
        lighting. ‍
      </>
    ),
  },
];
