import styled from "styled-components";
import padding from "@src/design-tokens/padding";
import margin from "@src/design-tokens/margin";
import { Alert, Card } from "react-bootstrap";

export const Wrapper = styled.div`
  form {
    margin-top: ${margin.m3};
  }

  button.btn,
  button.btn.btn-primary {
    align-items: center;
    display: flex;
    font-size: 1.1rem;
    justify-content: center;
    margin-bottom: ${margin.m3};
    padding: ${padding.p3};
    text-align: center;
    text-transform: uppercase;
    width: 100%;
  }
`;

export const NetworkAlert = styled(Alert)`
  text-align: center;
  border-radius: 8px;
  font-weight: bold;
  border: solid 1px var(--error-color);
  color: var(--error-color);
`;

export const MessageCard = styled(Card)`
  background-color: var(--accent-10);
  border: none;
  border-radius: 8px;
  .card-body > div > div {
    &:before,
    &:after {
      border-color: var(--main-text-color);
    }
  }
`;
