/* eslint-disable react/no-array-index-key */
import { useEffect, useState, ReactElement } from "react";
import { renderToString } from "react-dom/server";
import goose1Preview from "@src/assets/images/Goose-NFT-Preview.gif";
import goose2Preview from "@src/assets/images/Goose2Preview.jpeg";
import hildabroomPreview from "@src/assets/images/hildabroom-banner.png";
import EasyTimer from "easytimer.js";
import { HeroSection } from "@/src/components/HeroSection";
import PageLayout from "@/src/components/shared/page-layout";
import ActionButton from "@/src/components/ActionButton";
import { timezoneOffset } from "@/src/core/support/timezone-offset";
import { artists } from "@/src/data/static/artistsContent";
import { ArtistSection } from "@/src/components/ArtistSection";
import { HighlightWrapper } from "@/src/components/offers/details/call-to-action/buy-now/styles";

const RootPage = (): JSX.Element => {
  const images = [goose1Preview, goose2Preview];
  const [anySoldOut, setAnySoldOut] = useState(false);
  const isLive = (startDate: Date) => {
    return new Date(startDate).valueOf() <= new Date().valueOf();
  };

  let getEthereumPrice: number | undefined;
  let grabListJsonData = "";
  let isShow = false;

  function isEqual(start: Date, end: Date) {
    return end.valueOf() === start.valueOf();
  }

  const setCountDown = (
    startDate: Date,
    index: number
  ): boolean | undefined => {
    const tempStartDate = timezoneOffset(new Date(startDate));

    const getCurrentDate = () => new Date().toUTCString();

    const hideCountdown = tempStartDate.getTime() <= new Date().getTime();

    if (hideCountdown) {
      return true;
    }

    const gribTimer = new EasyTimer();
    const gribCountdownContainer = document.getElementById(
      `grabListCountDown${index > -1 ? index : "Top"}`
    );

    const currentDate = new Date(getCurrentDate());

    const distance = tempStartDate.valueOf() - currentDate.valueOf();

    const daysLeft = Math.floor(distance / (1000 * 60 * 60 * 24));

    const hoursLeft = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );

    const minutesLeft = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const secondsLeft = Math.floor((distance % (1000 * 60)) / 1000);

    if (!gribCountdownContainer) return undefined;

    // debugger;

    gribTimer.start({
      countdown: true,
      startValues: {
        days: daysLeft,
        seconds: secondsLeft,
        minutes: minutesLeft,
        hours: hoursLeft,
      },
    });

    const displayedValues: any = [
      daysLeft ? "days" : undefined,
      "hours",
      "minutes",
      "seconds",
    ].filter(Boolean);

    gribCountdownContainer.innerHTML = gribTimer
      .getTimeValues()
      .toString(displayedValues)
      .replaceAll(":", " : ");

    gribTimer.addEventListener("secondsUpdated", (_e) => {
      gribCountdownContainer.innerHTML = gribTimer
        .getTimeValues()
        .toString(displayedValues)
        .replaceAll(":", " : ");
    });

    gribTimer.addEventListener("targetAchieved", (_e) => {
      function delay(time: number) {
        return new Promise((resolve) => setTimeout(resolve, time));
      }
      delay(1000).then(() => grabOffersShow());
    });

    return undefined;
  };

  function grabOffersShow() {
    if (isShow) {
      const ethereumUsd: any = getEthereumPrice;
      const json: any = grabListJsonData;

      const getDate = (date: Date) => {
        const today = new Date(date);

        return new Date(
          `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
        );
      };

      const grabListElement = document.getElementById("grabList");
      const topGrabElement = document.getElementById("topGrab");

      if (!grabListElement) return;
      if (!topGrabElement) return;

      let grabList = "";
      let isBlur = "";
      let showCountdown = "";
      let isShowTopGrab = false;
      let showTopGrab: string | JSX.Element = "";
      let tempDate: Date = new Date();
      let finalTopGridDate: Date = tempDate;

      const dates = [];

      const offerCount = json.body.data.length;

      for (let i = 0; i < offerCount; i++) {
        if (!isLive(json.body.data[i].attributes.startsAt)) {
          dates.push(new Date(json.body.data[i].attributes.startsAt));
        }
      }

      const showTopGrideDate = new Date(
        Math.min.apply(
          null,
          dates.map((item) => item.valueOf())
        )
      );

      const offers: any = [];

      for (let i = 0; i < offerCount; i++) {
        if (json.body.data[i].attributes.availableCount > 0) {
          offers.unshift(json.body.data[i]);
        } else {
          offers.push(json.body.data[i]);
        }
      }

      for (let i = 0; i < offerCount; i++) {
        tempDate = new Date(offers[i].attributes.startsAt);

        if (!isLive(timezoneOffset(offers[i].attributes.startsAt))) {
          isBlur = "blur-pro";
          showCountdown = "showCountdown";

          if (isEqual(showTopGrideDate, tempDate)) {
            if (!isShowTopGrab) {
              finalTopGridDate = tempDate;
              showTopGrab = renderToString(
                <>
                  {renderHero(
                    <div className="time-area">
                      <div>
                        <div
                          className="countdown"
                          id="grabListCountDownTop"
                          data-show-on-upcoming-drop
                        />
                      </div>
                    </div>
                  )}
                </>
              );
              topGrabElement.innerHTML = showTopGrab;
              isShowTopGrab = true;
            }
          }
        } else {
          isBlur = "";
          showCountdown = "";

          if (isEqual(getDate(new Date()), getDate(tempDate))) {
            showTopGrab = renderToString(
              <>
                {renderHero(
                  <ActionButton
                    href="#shop"
                    text="Explore the drop"
                    hoverText={undefined}
                  />
                )}
              </>
            );
            topGrabElement.innerHTML = showTopGrab;
            isShowTopGrab = true;
          }
        }

        const offerImage = images.find((item) => {
          return item.indexOf(offers[i].attributes.previewUrlSmall) > -1;
        });

        const offerMediaType = offers[i].attributes.mediaMimeType;

        let mintButtonText = "Mint Now";
        const soldOut = offers[i].attributes.availableCount === "0";
        if (soldOut) {
          mintButtonText = "Sold Out!";
          setAnySoldOut(true);
        }

        const previewMedia =
          offerMediaType === "video/mp4"
            ? `<video autoplay muted loop><source src='${
                offerImage !== undefined
                  ? offerImage
                  : offers[i].attributes.previewUrlSmall
              }' type='video/mp4'></video>`
            : `<img src='${offers[i].attributes.previewUrlSmall}' alt=''>`;

        grabList =
          `${grabList}<div class="${
            showCountdown || ""
          }"><div class="girbBox ${isBlur}${
            soldOut ? " disabled" : ""
          }" data-grab-box >` +
          `<a href="${process.env.REACT_APP_DOMAIN}/offers/${offers[i].attributes.offerId}" class="innerbox1">` +
          `<div class='preview-media'>${previewMedia}</div>` +
          `</a>` +
          `<div class="title-box"><table><tr class="firstRow">` +
          `<th class="f-text">${offers[i].attributes.name}</th>` +
          `<td class="n-text" rowspan="2">${
            offers[i].attributes.totalItems -
            offers[i].attributes.availableCount
          }/${offers[i].attributes.totalItems}</td>` +
          `</tr></table><table><tr><td class="artist">by <b>${offers[i].attributes.artist}</b></td></tr></table><hr><table><tr><td class="price" colspan="2">Price</td></tr><tr><td class="td-text" colspan="2">${json.body.data[i].attributes.price}ETH  &nbsp; <span class="n-text">` +
          `<span class="usd">
          ${
            ethereumUsd
              ? formatUsd(offers[i].attributes.price * ethereumUsd)
              : ""
          }</span>` +
          `</span></td></tr></table>` +
          `<a href="${process.env.REACT_APP_DOMAIN}/offers/${offers[i].attributes.offerId}"	class="bid" style="text-decoration: none;">` +
          `<span> ${mintButtonText} </span></a></div></div><div class="countDownOverlay" ><div class="droppingIn">DROPPING IN</div><div id="grabListCountDown${i}" class='grabListCountdown'></div></div></div>`;

        grabListElement.innerHTML = grabList;
      }

      grabListElement.innerHTML = grabList;

      for (let i = 0; i < json.body.data.length; i++) {
        if (!isShowTopGrab) {
          showTopGrab = renderToString(
            <>
              {renderHero(
                <ActionButton
                  href="#shop"
                  text="Explore the drop"
                  hoverText={undefined}
                />
              )}
            </>
          );
          topGrabElement.innerHTML = showTopGrab;
          isShowTopGrab = true;
        }
      }

      isShowTopGrab = false;

      for (let i = 0; i < json.body.data.length; i++) {
        setCountDown(json.body.data[i].attributes.startsAt, i);
        if (
          isEqual(
            finalTopGridDate,
            new Date(json.body.data[i].attributes.startsAt)
          )
        ) {
          setCountDown(json.body.data[i].attributes.startsAt, -1);
          isShowTopGrab = true;
        }
      }
    }
  }

  const getEthereumUsd = () => {
    fetch(
      "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
    )
      .then((response) => response.json())
      .then((json) => {
        getEthereumPrice = json.ethereum.usd;
      })
      .catch((error) => console.error(error));
  };

  const grabOffers = () => {
    // fetch(`${process.env.REACT_APP_OFFERS_API_URL}/`)
    fetch(
      `${process.env.REACT_APP_DROPS_API_URL}/${process.env.REACT_APP_MAIN_DROP_ID}/offers`
    )
      .then((response) => response.json())
      .then((json) => {
        grabListJsonData = json;
        isShow = true;
        grabOffersShow();
      })
      .catch((error) => console.error(error));
  };

  function formatUsd(val: number) {
    const formattedPrice = new Intl.NumberFormat("en-US", {
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: "currency",
    }).format(val);
    return formattedPrice;
  }

  useEffect(() => {
    getEthereumUsd();
    grabOffers();
  }, []);

  return (
    <PageLayout>
      <>
        {renderHero(<></>)}
        <section id="shop" className="collection-area">
          <div className="container">
            <div className="collection-title">
              <h2 style={{ paddingLeft: "0" }}>Shop the Collection</h2>
              <p className="content1">
                In late 2022 hildabroom teamed up with an amazing and diverse
                set of artists to curate a special drop signifying a moment in
                time.
              </p>
            </div>
            <div id="grabList" />
            {anySoldOut && (
              <HighlightWrapper className="under-offers">
                <p>
                  NOTE: If an item appears sold out, be sure to check back
                  periodically as items may become available again in the event
                  of a failed or abandoned transaction.
                </p>
              </HighlightWrapper>
            )}
          </div>
        </section>
        <section className="about-area" id="about">
          <div className="container about-container">
            <div className="about-title-2">
              <h2>About the Collection</h2>
              <p className="content1">
                What began as a simple hildabroom-written prompt turned into the
                beautiful set of 5 unique works you see above. Each of the 5
                artists hail from across the globe and are creating through a
                spectrum of different art styles daily. Whether it&apos;s Irina
                with a paintbrush, Anzomez via purple glitch, Manfredi amidst
                computer-generated vastness, Bojan through intense emotion or
                for bbdrip, an underwater camera lens -- each artist brought
                their feelings to life for &ldquo;We Must See the Light&rdquo;.
              </p>
              <br />
              <h4>As for the prompt?</h4>
              <p className="content1">
                It&apos;s been a hellishly long 3 years of Covid, heck it&apos;s
                still here, and we&apos;re amidst some gloomy months dragging
                through the Winter. As you look to Spring and recover some hope
                for the future you begin to see that speck of light at the end
                of the tunnel. The light gleaming through the keyhole of the
                door to your bedroom. You can feel in your bones the sun peeking
                over the mountains bringing warmth to the cold plain of your
                existence... What sort of feelings does this elicit? How would
                you illustrate these emotions through your art?
              </p>
            </div>
          </div>
        </section>

        {artists &&
          artists.map((artist, i) => {
            return (
              <ArtistSection
                key={artist.name}
                name={artist.name}
                id={i}
                description={artist.description}
              />
            );
          })}
        {/* <Link to={`/drops/${uuid()}`}>onClick here to see a sample drop</Link> */}
      </>
    </PageLayout>
  );
};

const HildabroomDescription = (): ReactElement => {
  return (
    <>
      <div style={{ opacity: ".5", marginBottom: "8px", fontWeight: 200 }}>
        Featuring art from:
      </div>
      {artists && (
        <ul className="artists" key="artists">
          {artists.map((artist, i) => {
            return (
              <li
                key={artist.name}
                style={{
                  display: `${artist.name === "hildabroom" ? "none" : ""}`,
                }}
              >
                <a href={`#artist_${i}`} style={{ textDecoration: "none" }}>
                  {artist.name}
                </a>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

const renderHero = (action: ReactElement): JSX.Element => {
  return (
    <HeroSection
      action={action}
      title="We Must See The Light"
      subtitle="A diverse range of works curated by hildabroom"
      description={<HildabroomDescription />}
      image={hildabroomPreview}
    />
  );
};

export default RootPage;
