import toparw from "@src/assets/images/top-arw.png";
import styled, { createGlobalStyle } from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import { rgba } from "polished";
import border from "@src/design-tokens/border";
import color from "@src/design-tokens/color";
import fontFamily from "@src/design-tokens/font-family";
import fontWeight from "@src/design-tokens/font-weight";
import margin from "@src/design-tokens/margin";
import padding from "@src/design-tokens/padding";

export const GlobalStyleWrapper = createGlobalStyle`
  #root > div {
    padding-top: 0;
  }
  body {
    color: ${color.primary.medium};
    font-family: ${fontFamily.workSans};
  }
`;

export const BootstrapOverrides = createGlobalStyle`
  .btn {
    border-width: 2px;
    border-radius: ${border.radius.circular};
    font-size: 1.1rem;
    font-weight: ${fontWeight.semibold};
    padding: ${padding.p3} ${padding.p4};

    &.btn-primary {
      background: var(--accent-30);
      border-color: transparent;

      &:active,
      &:focus,
      &:hover {
        background: var(--accent-30);
        border-color: var(--accent-color);
      }

      &:active,
      &:focus {
        box-shadow: 0 0 0 0.25rem ${rgba(color.primary.medium, 0.5)};
      }
    }

    &.btn-light {
      background: ${color.primary.medium};
      border-color: ${color.neutral.medium};
      color: ${color.neutral.medium};

      &:active,
      &:focus,
      &:hover {
        background: ${color.neutral.medium};
        border-color: ${color.neutral.medium};
        color: ${color.primary.medium};
      }

      &:active,
      &:focus {
        box-shadow: 0 0 0 0.25rem rgb(${color.primary.medium} / 50%);
      }
    }

    &.btn-secondary {
      background: ${color.neutral.medium};
      border-color: var(--main-text-color);
      color: ${color.primary.medium};

      &:active,
      &:focus,
      &:hover {
        background: ${color.primary.medium};
        border-color: ${color.primary.medium};
        color: ${color.neutral.medium};
      }

      &:active,
      &:focus {
        box-shadow: 0 0 0 0.25rem rgb(${color.primary.medium} / 50%);
      }
    }
  }

  .card {
    border-color: var(--main-text-color);

    &.card-3d {
      border: 0;
      box-shadow: 7px 10px 40px rgba(13, 52, 79, 0.1);
    }

    .card-body {
      padding: ${padding.p4};
    }

    .card-image {
      border-bottom-left-radius: ${border.radius.none};
      border-bottom-right-radius: ${border.radius.none};
      height: 15rem;
    }

    .card-title {
      color: ${color.primary.light};
      font-size: 1.25rem;
      font-weight: ${fontWeight.bold};
    }
  }

  .modal-backdrop {
    &.show {
      opacity: 0.8;
    }
  }


  .nav-pills {
    .nav-link {
      background:  ${color.neutral.medium};
      border-radius: ${border.radius.none};
      box-shadow: 3px 5px 20px rgba(0, 0, 0, 0.06);
      color: ${color.primary.medium};
      cursor: pointer;
      font-size: 1.2rem;
      margin-bottom: ${margin.m3};
      padding: ${padding.p3} ${padding.p4};
      text-align: center;
    }

    .nav-link.active, .show>.nav-link {
      background: ${color.primary.medium};
    }
  }

  a {
    color: ${color.primary.medium};
    font-size: 0.9rem;
    font-weight: ${fontWeight.semibold};
  }

  form {
    [class^="col"] {
      & > div {
        margin-bottom: ${margin.m3};
      }
    }

    .form-control:disabled,
    .form-control[readonly] {
      background-color: var(--main-text-color);
    }

    .form-label {
      font-weight: ${fontWeight.medium};
    }
  }

  p {
    color: ${color.primary.light}
  }
`;

const Container = styled.div`
  .accordion-button:not(.collapsed)::after {
    background-image: url(${toparw});
    transform: rotate(0deg);
  }

  .accordion-button::after {
    background-image: url(${toparw});
    transform: rotate(-180deg);
  }
`;
