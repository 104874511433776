import {
  createContext,
  useState,
  Dispatch,
  ReactElement,
  useEffect,
} from "react";

import detectEthereumProvider from "@metamask/detect-provider";
import { ChainType, Wallet, WalletType } from "@src/core/models/wallet";
import Web3 from "web3";

const w3 = new Web3(Web3.givenProvider);
export interface MetaMaskWalletContextProps {
  metaMaskWallet?: Wallet;
  setMetaMaskWallet?: Dispatch<Wallet | undefined>;
}

interface MetaMaskWalletProviderProps {
  children: ReactElement;
}

export const MetaMaskWalletContext = createContext<MetaMaskWalletContextProps>(
  {}
);

export const EVENT_GET_BALANCE = "eth_getBalance";
export const EVENT_GET_CHAIN_ID = "eth_chainId";
export const EVENT_LIST_ACCOUNTS = "eth_accounts";
export const EVENT_REQUEST_ACCOUNTS = "eth_requestAccounts";
export const CALLBACK_ACCOUNTS_CHANGED = "accountsChanged";
export const CALLBACK_CHAIN_CHANGED = "chainChanged";

// const networkChainId="0x89"; // Polygon
// const networkChainId = "0x13881"; // Mumbai
// const networkChainId = "0x5"; // Goerli
const networkChainId = "0x1"; // Mainnet
const networkParams = [
  {
    chainId: "0x1",
    rpcUrls: ["https://rpc.ankr.com/eth"],
    chainName: "Mainnet",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    blockExplorerUrls: ["https://etherscan.io/"],
  },
];
/*  {
    chainId: "0x5",
    rpcUrls: ["https://goerli.blockpi.network/v1/rpc/public"],
    chainName: "Goerli test network",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    blockExplorerUrls: ["https://goerli.etherscan.io//"],
  },
];
// MUMBAI  {
    chainId: "0x13881",
    rpcUrls: ["https://rpc-mumbai.maticvigil.com/"],
    chainName: "Mumbai Testnet",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    blockExplorerUrls: ["https://explorer-mumbai.maticvigil.com/"],
  },
];

POLYGON
    [
      {
        chainId: "0x89",
        rpcUrls: ["https://polygon-rpc.com/"],
        chainName: "Polygon Mainnet",
        nativeCurrency: {
          name: "MATIC",
          symbol: "MATIC",
          decimals: 18,
        },
        blockExplorerUrls: ["https://polygonscan.com/"],
      },
    ]
*/

export const MetaMaskWalletProvider = ({
  children,
}: MetaMaskWalletProviderProps): JSX.Element => {
  const [wallet, setWallet] = useState<Wallet>();

  useEffect(() => {
    if (window.ethereum) {
      handleEthereum();
    } else {
      window.addEventListener("ethereum#initialized", handleEthereum, {
        once: true,
      });
      // If the event is not dispatched by the end of the timeout,
      // the user probably doesn't have MetaMask installed.
      setTimeout(handleEthereum, 3000); // 3 seconds
    }
    if (!getEthereum()) {
      showExtensionNotFoundWarning();
      return;
    }
    signWalletEvents(setWallet);
    setPreviouslyConnectedAccount(setWallet);
  }, [setWallet]);

  function handleEthereum() {
    const { ethereum } = window;
    if (ethereum && ethereum.isMetaMask) {
      console.log("Ethereum successfully detected!");
      forceNetwork();
      // Access the decentralized web!
    } else {
      console.log("Please install MetaMask!");
    }
  }

  return (
    <MetaMaskWalletContext.Provider
      value={{ metaMaskWallet: wallet, setMetaMaskWallet: setWallet }}
    >
      {children}
    </MetaMaskWalletContext.Provider>
  );
};

export const signWalletEvents = (
  callback: (wallet: Wallet | undefined) => void
): void => {
  getEthereum().on(CALLBACK_ACCOUNTS_CHANGED, (accountsList: string[]) => {
    handleAccountChange(accountsList, callback);
    forceNetwork();
  });

  getEthereum().on(CALLBACK_CHAIN_CHANGED, () => {
    getAccountAddress().then((accountsList: string[]) => {
      handleAccountChange(accountsList, callback);
    });
  });
};

export const promptMetaMaskWalletConnection = async () => {
  const provider = await detectEthereumProvider();
  if (!provider) {
    showExtensionNotFoundWarning();
    return;
  }
  const ethereum = getEthereum();
  ethereum
    .request({
      method: EVENT_REQUEST_ACCOUNTS,
    })
    .catch(console.log);
};

const setPreviouslyConnectedAccount = (
  callback: (wallet: Wallet | undefined) => void
): void => {
  return getEthereum()
    .request({ method: EVENT_LIST_ACCOUNTS })
    .then((accountsList: string[]) => {
      handleAccountChange(accountsList, callback);
    });
};

const handleAccountChange = async (
  accountsList: string[],
  callback: (wallet: Wallet | undefined) => void
): Promise<void> => {
  if (!callback) return;
  const accountId = accountsList[0];
  if (!accountId) {
    callback(undefined);
    return;
  }

  const accountBalance = await getAccountBalance(accountId);
  const gasPrice = await getGasPrice();
  const chainId = await getAccountChainId(accountId);
  console.log(chainId);
  console.log({
    balance: accountBalance,
    id: accountId,
    type: WalletType.MetaMask,
    chainId,
    gasPrice,
  });
  callback({
    balance: accountBalance,
    id: accountId,
    type: WalletType.MetaMask,
    chainId,
    gasPrice,
  });
};

const getAccountAddress = async () => {
  return getEthereum()
    .request({ method: EVENT_LIST_ACCOUNTS })
    .then((accountsList: string[]) => {
      return accountsList;
    })
    .catch(console.log);
};

const getAccountBalance = async (accountId: string) => {
  return getEthereum()
    .request({ method: EVENT_GET_BALANCE, params: [accountId, "latest"] })
    .then((balance: string) => {
      return Web3.utils.fromWei(balance, "ether");
    })
    .catch(console.log);
};

const getGasPrice = async () => {
  const gasPrice = await w3.eth.getGasPrice();
  console.log(gasPrice);
  return Number(Web3.utils.fromWei(gasPrice, "ether")) ?? 0;
};

const forceNetwork = async () => {
  try {
    // check if the chain is installed in MM
    await getEthereum().request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: networkChainId }],
    });
  } catch (error: any) {
    // This error code indicates that the chain has not been added to MetaMask
    // if it is not, then install it into the user MetaMask
    if (error.code === 4902) {
      try {
        await getEthereum().request({
          method: "wallet_addEthereumChain",
          params: networkParams,
        });
      } catch (addError) {
        console.error(addError);
      }
    }
    console.error(error);
  }
};

const getAccountChainId = async (accountId: string): Promise<ChainType> => {
  return getEthereum()
    .request({ method: EVENT_GET_CHAIN_ID, params: [accountId, "latest"] })
    .then((chainId: string) => {
      return chainId as ChainType;
    })
    .catch(console.log);
};

const showExtensionNotFoundWarning = (): void => {
  const message = "Please install the MetaMask extension on your browser.";
  console.log(message);
};

const getEthereum = () => (window as any).ethereum;
