import { useContext, useState } from "react";
import { Nav } from "react-bootstrap";
import { MetaMaskWalletContext } from "@src/core/contexts/meta-mask-wallet";
import WalletDetails from "@src/components/wallet/details";
import WalletOpenModalButton from "@src/components/wallet/open-modal-button";
import { MessageBar } from "./styles";

const HildabroomLogo = () => {
  return (
    <div className="logo">
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 57.51 51"
      >
        <g id="Layer_1-2" data-name="Layer 1">
          <g>
            <path
              className="cls-1"
              d="m49.31.15l.58,4.16c.28,1.98,1.74,3.56,3.63,3.9l3.85.71c.19.03.19.32,0,.35l-3.85.71c-1.89.35-3.35,1.92-3.63,3.9l-.58,4.16c-.03.2-.31.2-.34,0l-.58-4.16c-.28-1.98-1.74-3.56-3.63-3.9l-3.85-.71c-.19-.03-.19-.32,0-.35l3.85-.71c1.89-.35,3.35-1.92,3.63-3.9l.58-4.16c.03-.2.31-.2.34,0Z"
            />
            <path
              className="cls-1"
              d="m20.78,51C9.32,51,0,41.68,0,30.22S9.32,9.44,20.78,9.44s20.78,9.32,20.78,20.78-9.32,20.78-20.78,20.78Zm0-39.33C10.55,11.67,2.23,19.99,2.23,30.22s8.32,18.55,18.55,18.55,18.55-8.32,18.55-18.55S31.01,11.67,20.78,11.67Z"
            />
            <circle className="cls-1" cx="26.71" cy="25.78" r="6.03" />
          </g>
        </g>
      </svg>
    </div>
  );
};

const Header = (): JSX.Element => {
  const { metaMaskWallet } = useContext(MetaMaskWalletContext);
  const [messageBar, setMessageBar] = useState<boolean>(true);
  const toggleNav = (visible: boolean): void => {
    const element = document.getElementById("myNav");

    if (element) element.style.width = visible ? "100%" : "0%";
  };

  return (
    <>
      <MessageBar className={messageBar ? "show" : "hide"}>
        <div>
          Note: for the best experience use Chrome or Firefox with the MetaMask
          browser extension, or the MetaMask app&lsquo;s in-app browser. New to
          purchasing NFTs with MetaMask? Read our{" "}
          <a href={`${process.env.REACT_APP_DOMAIN}/faqs`}>FAQs</a>
        </div>
        <button type="button" onClick={() => setMessageBar(false)}>
          ✕
        </button>
      </MessageBar>
      <header className="header-area" id="home">
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <div className="inner-header-container">
              <a className="navbar-brand studio" href="/">
                <HildabroomLogo />
                <span>hildabroom</span>
              </a>
              <div
                className="navbar-collapse colps"
                id="navbarNav"
                style={{ flexBasis: "content" }}
              >
                <ul className="navbar-nav ms-auto">
                  <li className="nav-item">
                    <a className="nav-link" href="/#about">
                      ABOUT
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/faqs">
                      FAQs
                    </a>
                  </li>
                  <li>
                    <Nav>
                      {!metaMaskWallet && <WalletOpenModalButton />}
                      {metaMaskWallet && <WalletDetails />}
                    </Nav>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
