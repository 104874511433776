import styled from "styled-components";

export const MessageBar = styled.div`
    font-size: 11px;
    line-height 16px;
    margin: 0 auto;
    width: 100%;
	background-color: var(--accent-10);
	color: #fff;
	font-family: var(--body-font);
	text-align: left;
    overflow: hidden;
    transition: all .333s ease-in-out;
    padding: 7px 16px;
    padding-right: 1.5rem;
    max-height: 100px;
    display: flex;
    justify-content: center;
    &.hide {
        max-height: 0px;
        padding-top: 0;
        padding-bottom: 0;
    }
    button {
        background-color: transparent;        
        border: 0px;
        font-size: 18px;
        margin-left: 16px;
        color: var(--accent-50);
    }

    a {
        color: var(--accent-color);
    }

    a, button {
        transition: color .25s ease-in-out;
        &:hover {
            color: var(--accent-color);
        }
    }

    @media (min-width: 900) {
        grid-gap: 2rem;
    }
    @media (min-width: 1200px) {
        text-align: center;
        font-size: 14px;
        line-height 21px;
        padding: 8px 8px
    }
`;
