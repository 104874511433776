import padding from "@src/design-tokens/padding";
import styled from "styled-components";

export const Wrapper = styled.div`
  .btn.btn-primary {
    padding: ${padding.p2} ${padding.p3};
    text-transform: uppercase;
    background-color: transparent;
    border: solid 1px var(--accent-50);
    color: var(--main-text-color);
    white-space: nowrap;
    &:focus {
      outline: 0px;
      box-shadow: none;
    }
    &:hover {
      color: var(--accent-color);
      border-color: var(--accent-color);
    }
  }
`;
