import { ReactElement, useEffect } from "react";
import WebFont from "webfontloader";
import fontFamily from "@src/design-tokens/font-family";
import fontWeight from "@src/design-tokens/font-weight";
import * as Styles from "./styles";

interface Props {
  children: ReactElement;
}

const GlobalStyleWrapper = ({ children }: Props): JSX.Element => {
  useEffect(loadFonts);

  return (
    <>
      <Styles.GlobalStyleWrapper />
      <Styles.BootstrapOverrides />
      {children}
    </>
  );
};

const loadFonts = (): void => {
  const formattedFontWeights = Object.values(fontWeight).join(",");

  WebFont.load({
    google: {
      families: [`${fontFamily.workSans}:${formattedFontWeights}`],
    },
  });
};

export default GlobalStyleWrapper;
