import { Button } from "react-bootstrap";
import styled from "styled-components";

export const DynamicButton = styled(Button)`
  display: block;
  position: relative;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.01em;
  display: inline-block;
  background-color: transparent;
  border-radius: 10px;
  text-decoration: none;
  margin: 16px 0 0 0;
  transition: all 0.333s ease-in-out;
  border: solid 1px;
  border-color: var(--main-text-color);
  text-transform: uppercase;
  text-align: center;
  height: 62px;
  width: 270px;
  overflow: hidden;
  > span {
    color: var(--main-text-color);
    display: block;
    white-space: nowrap;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: all 0.333s ease-in-out;
    &.hover-text {
      transform: translateX(-50%) translateY(calc(-50% + 50px));
    }
  }
  &.btn-primary:hover {
    background-color: var(--accent-color);
    &.has-hover-text {
      span.button-text {
        transform: translateX(-50%) translateY(calc(-50% - 50px));
      }
      span.hover-text {
        transform: translateX(-50%) translateY(-50%);
      }
    }
  }
`;
