import styled from "styled-components";

export const ArtistSectionWrapper = styled.section`
  width: 100%;
  margin: 120px 0;
  .artist-title {
    h2 {
      font-family: var(--display-font);
      font-weight: 900;
      line-height: 148%;
      letter-spacing: 0.01em;
    }
    p {
      font-size: 18px;
      line-height: 159%;
      letter-spacing: 0.01em;
      color: var(--main-text-color);
      font-family: var(--body-font);
      a {
        font-size: 18px;
        line-height: 159%;
        // text-decoration: none;
        color: var(--accent-color);
        &:hover {
          color: var(--accent-80);
        }
      }
    }
  }
`;
